import { Injectable } from '@angular/core';
import { createSelector } from 'reselect';

import { State } from '@core/reducers';
import * as fromExportHistoryLogs from '@core/reducers/export-history-logs.reducer';

@Injectable()
export class ExportHistoryLogsSelectors {
    /**
     * Get state.exportHistoryLogs
     */
    getExportHistoryState() {
        return ($state: State): any => $state.exportHistoryLogs;
    }

    /**
     * Get list from state.exportHistoryLogs
     */
    getHistoryList() {
        return createSelector(
            this.getExportHistoryState(),
            fromExportHistoryLogs.getList,
        );
    }

    /**
     * Get imagePlatformLog from state.exportHistoryLogs
     */
    getImagePlatformLog() {
        return createSelector(
            this.getExportHistoryState(),
            fromExportHistoryLogs.getImagePlatformLog,
        );
    }

    /**
     * Get mediaLog from state.exportHistoryLogs
     */
    getMediaLog() {
        return createSelector(
            this.getExportHistoryState(),
            fromExportHistoryLogs.getMediaLog,
        );
    }

    /**
     * Get dataLog from state.exportHistoryLogs
     */
    getDataLog() {
        return createSelector(
            this.getExportHistoryState(),
            fromExportHistoryLogs.getDataLog,
        );
    }

    getSharingHistoryLog() {
        return createSelector(
            this.getExportHistoryState(),
            fromExportHistoryLogs.getSharingHistoryLog,
        );
    }
}
