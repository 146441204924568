import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Export History Logs]';

export const ActionTypes = {
    GET_LIST_REQUEST: type(`${ACTION_PREFIX} Get List Request`),
    GET_LIST_SUCCESS: type(`${ACTION_PREFIX} Get List Success`),
    GET_LIST_ERROR: type(`${ACTION_PREFIX} Get List Error`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),

    GET_IMAGE_PLATFORM_LOG_REQUEST: type(`${ACTION_PREFIX} Get Image Platform Logs Request`),
    GET_IMAGE_PLATFORM_LOG_SUCCESS: type(`${ACTION_PREFIX} Get Image Platform Logs Success`),
    GET_IMAGE_PLATFORM_LOG_ERROR: type(`${ACTION_PREFIX} Get Image Platform Logs Error`),

    GET_DATA_LOG_REQUEST: type(`${ACTION_PREFIX} Get Data Logs Request`),
    GET_DATA_LOG_SUCCESS: type(`${ACTION_PREFIX} Get Data Logs Success`),
    GET_DATA_LOG_ERROR: type(`${ACTION_PREFIX} Get Data Logs Error`),
    
    GET_SHARING_HISTORY_LOG_REQUEST: type(`${ACTION_PREFIX} Get Sharing History Logs Request`),
    GET_SHARING_HISTORY_LOG_SUCCESS: type(`${ACTION_PREFIX} Get Sharing History Logs Success`),
    GET_SHARING_HISTORY_LOG_ERROR: type(`${ACTION_PREFIX} Get Sharing History Logs Error`),

    GET_MEDIA_LOG_REQUEST: type(`${ACTION_PREFIX} Get Media Logs Request`),
    GET_MEDIA_LOG_SUCCESS: type(`${ACTION_PREFIX} Get Media Logs Success`),
    GET_MEDIA_LOG_ERROR: type(`${ACTION_PREFIX} Get Media Logs Error`),

    CLEAR_LOG: type(`${ACTION_PREFIX} Clear Log`),
};

/**
 * Create 'Get List Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetListRequestAction implements Action {
    type = ActionTypes.GET_LIST_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Succes' Action
 * @param payload Contains object with workflow history logs list and params
 */
export class GetListSuccessAction implements Action {
    type = ActionTypes.GET_LIST_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get List Error' Action
 * @param payload Contains object with errors
 */
export class GetListErrorAction implements Action {
    type = ActionTypes.GET_LIST_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Platform Log Request' Action
 * @param payload Contains object with list params
 */
export class GetImagePlatformLogRequestAction implements Action {
    type = ActionTypes.GET_IMAGE_PLATFORM_LOG_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Platform Log Success' Action
 * @param payload Contains object with single import history log and params
 */
export class GetImagePlatformLogSuccessAction implements Action {
    type = ActionTypes.GET_IMAGE_PLATFORM_LOG_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Platform Log Error' Action
 * @param payload Contains object with errors
 */
export class GetImagePlatformLogErrorAction implements Action {
    type = ActionTypes.GET_IMAGE_PLATFORM_LOG_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Log Request' Action
 * @param payload Contains object with list params
 */
export class GetDataLogRequestAction implements Action {
    type = ActionTypes.GET_DATA_LOG_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Log Success' Action
 * @param payload Contains object with single import history log and params
 */
export class GetDataLogSuccessAction implements Action {
    type = ActionTypes.GET_DATA_LOG_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Data Log Error' Action
 * @param payload Contains object with errors
 */
export class GetDataLogErrorAction implements Action {
    type = ActionTypes.GET_DATA_LOG_ERROR;

    constructor(public payload?: any) {}
}
export class GetSharingHistoryLogRequestAction implements Action {
    readonly type = ActionTypes.GET_SHARING_HISTORY_LOG_REQUEST;
    constructor(public payload?: any) {}
}

export class GetSharingHistoryLogSuccessAction implements Action {
    readonly type = ActionTypes.GET_SHARING_HISTORY_LOG_SUCCESS;
    constructor(public payload?: any) {}
}

export class GetSharingHistoryLogErrorAction implements Action {
    readonly type = ActionTypes.GET_SHARING_HISTORY_LOG_ERROR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Log Request' Action
 * @param payload Contains object with list params
 */
export class GetMediaLogRequestAction implements Action {
    type = ActionTypes.GET_MEDIA_LOG_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Log Success' Action
 * @param payload Contains object with single import history log and params
 */
export class GetMediaLogSuccessAction implements Action {
    type = ActionTypes.GET_MEDIA_LOG_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Media Log Error' Action
 * @param payload Contains object with errors
 */
export class GetMediaLogErrorAction implements Action {
    type = ActionTypes.GET_MEDIA_LOG_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Log' Action
 */
export class ClearLogAction implements Action {
    type = ActionTypes.CLEAR_LOG;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetListRequestAction
    | GetListSuccessAction
    | GetListErrorAction
    | ClearListAction
    | GetImagePlatformLogRequestAction
    | GetImagePlatformLogSuccessAction
    | GetImagePlatformLogErrorAction
    | GetDataLogRequestAction
    | GetDataLogSuccessAction
    | GetDataLogErrorAction
    | GetSharingHistoryLogRequestAction
    | GetSharingHistoryLogSuccessAction
    | GetSharingHistoryLogErrorAction
    | GetMediaLogRequestAction
    | GetMediaLogSuccessAction
    | GetMediaLogErrorAction
    | ClearLogAction;
