import * as exportHistoryLogsActions from '@core/actions/export-history-logs.actions';
import {
    DataExportHistoryLogData,
    ExportHistoryLogsList,
    ImagePlatformExportHistoryLogData,
    MediaExportHistoryLogData,
    SharingHistoryExportHistoryLogData,
} from '@core/models';

export interface State {
    list: ExportHistoryLogsList;
    imagePlatformLog: ImagePlatformExportHistoryLogData;
    mediaLog: MediaExportHistoryLogData;
    dataLog: DataExportHistoryLogData;
    sharingHistoryLog: SharingHistoryExportHistoryLogData;
}

const initialState: State = {
    list: {
        data: {
            count: null,
            limit: null,
            offset: null,
            next: null,
            previous: null,
            results: [],
        },
        errors: {},
        isFetching: true,
    },
    imagePlatformLog: {
        data: {
            created: '',
            dataType: '',
            exportedFile: '',
            exportedFileName: '',
            exportedMediasCount: 0,
            finished: '',
            id: null,
            mode: '',
            productsCount: 0,
            status: '',
            presetDpi: '',
            presetHeight: '',
            presetNameFormat: '',
            presetWidth: '',
        },
        errors: {},
        isFetching: true,
    },
    dataLog: {
        data: {
            created: '',
            dataType: '',
            exportedFile: '',
            exportedFileName: '',
            finished: '',
            fileType: '',
            fileSize: 0,
            id: null,
            mode: '',
            productsCount: 0,
            status: '',
        },
        errors: {},
        isFetching: true,
    },
    sharingHistoryLog: {
        data: {
            created: '',
            dataType: '',
            exportedFile: '',
            exportedFileName: '',
            finished: '',
            fileType: '',
            fileSize: 0,
            id: null,
            mode: '',
            productsCount: 0,
            status: '',
        },
        errors: {},
        isFetching: false,
    },
    mediaLog: {
        data: {
            created: '',
            dataType: '',
            exportedImagesCount: 0,
            exportedFile: '',
            exportedFileName: '',
            exportedFilesCount: 0,
            exportedVideosCount: 0,
            finished: '',
            id: null,
            mode: '',
            productsCount: 0,
            status: '',
        },
        errors: {},
        isFetching: true,
    },
};

export function reducer(state = initialState, action: exportHistoryLogsActions.Actions) {
    switch (action.type) {
        case exportHistoryLogsActions.ActionTypes.GET_LIST_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_LIST_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_LIST_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_IMAGE_PLATFORM_LOG_REQUEST: {
            return {
                ...state,
                imagePlatformLog: {
                    ...state.imagePlatformLog,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_IMAGE_PLATFORM_LOG_SUCCESS: {
            return {
                ...state,
                imagePlatformLog: {
                    ...state.imagePlatformLog,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_IMAGE_PLATFORM_LOG_ERROR: {
            return {
                ...state,
                imagePlatformLog: {
                    ...state.imagePlatformLog,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_DATA_LOG_REQUEST: {
            return {
                ...state,
                dataLog: {
                    ...state.dataLog,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_DATA_LOG_SUCCESS: {
            return {
                ...state,
                dataLog: {
                    ...state.dataLog,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_DATA_LOG_ERROR: {
            return {
                ...state,
                dataLog: {
                    ...state.dataLog,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_SHARING_HISTORY_LOG_REQUEST: {
            return {
                ...state,
                sharingHistoryLog: {
                    ...state.sharingHistoryLog,
                    errors: initialState.sharingHistoryLog.errors,
                    isFetching: true,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_SHARING_HISTORY_LOG_SUCCESS: {
            return {
                ...state,
                sharingHistoryLog: {
                    ...state.sharingHistoryLog,
                    data: action.payload,
                    errors: initialState.sharingHistoryLog.errors,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_SHARING_HISTORY_LOG_ERROR: {
            return {
                ...state,
                sharingHistoryLog: {
                    ...state.sharingHistoryLog,
                    data: initialState.sharingHistoryLog.data,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_MEDIA_LOG_REQUEST: {
            return {
                ...state,
                mediaLog: {
                    ...state.mediaLog,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_MEDIA_LOG_SUCCESS: {
            return {
                ...state,
                mediaLog: {
                    ...state.mediaLog,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.GET_IMAGE_PLATFORM_LOG_ERROR: {
            return {
                ...state,
                imagePlatformLog: {
                    ...state.imagePlatformLog,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case exportHistoryLogsActions.ActionTypes.CLEAR_LOG: {
            return {
                ...state,
                imagePlatformLog: {
                    ...initialState.imagePlatformLog,
                },
                mediaLog: {
                    ...initialState.mediaLog,
                },
                dataLog: {
                    ...initialState.dataLog,
                },
                sharingHistoryLog: {
                    ...initialState.sharingHistoryLog,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getImagePlatformLog = (state: State) => state.imagePlatformLog;
export const getDataLog = (state: State) => state.dataLog;
export const getSharingHistoryLog = (state: State) => state.sharingHistoryLog;
export const getMediaLog = (state: State) => state.mediaLog;
